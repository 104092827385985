import React from "react";
import { Button } from "antd";
import "./style.css";

const ButtonBasic = ({
  onClick,
  style,
  loading,
  className,
  htmlType,
  children,
  size,
}) => (
  <Button
    size={size}
    className={`basic-button ${className}`}
    onClick={onClick}
    loading={loading}
    htmlType={htmlType}
    style={style}
  >
    {children}
  </Button>
);

export default ButtonBasic;
