import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getAntrean: ["data"],
});

export const DashboardAntreanTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  antrean: null,
  detailAntrean: null,
});

/* ------------- Selectors ------------- */

export const DashboardAntreanSelectors = {
  getData: (state) => state.data,
};

/* ------------- Reducers ------------- */

// request the data from an api
export const requestAntrean = (state, { data }) =>
  state.merge({ antrean: data.antrian, detailAntrean: data.detailAntrian });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ANTREAN]: requestAntrean,
});
