import React, { useEffect } from "react";
import { Form, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { hideMessage } from "../appRedux/actions";

import AuthActions from "../appRedux/reducers/AuthRedux";

import { useState } from "react";
import modalAlert from "../components/Modal/ShowAlert";
import ButtonBasic from "../components/Button/ButtonBasic";

const LoginAntreanDashboard = () => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );
  const history = useHistory();

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push("/");
    }
  });

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    if (selectedFile) {
      dispatch(
        // AuthActions.signinUser({ ...values, userLogin: "ADMIN" }, history)
        AuthActions.signinDashboardAntrean(fileContent)
      );
    } else {
      modalAlert("Anda belum mengupload file");
    }

    // dispatch(showAuthLoader());
    // dispatch(userSignIn(values));
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileContent, setFileContent] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // Pastikan hanya file dengan format .txt yang diizinkan
    if (file && file.name.endsWith(".txt")) {
      setSelectedFile(file);

      // Membaca isi file
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;

        setFileContent(content);
      };
      reader.readAsText(file);
    } else {
      modalAlert("Hanya file dengan format .txt yang diizinkan");
      event.target.value = "";
    }
  };

  return (
    <div className="gx-login-container" style={{ background: "#F1582E" }}>
      <div className="gx-login-content">
        <div className="gx-login-header gx-text-center">
          <img width={180} src={"/assets/images/desapedia-01.png"} />
        </div>
        <div className="gx-text-center gx-mb-3">
          <h2>Upload file untuk melakukan login</h2>
        </div>
        <Form
          layout="vertical"
          initialValues={{ remember: true }}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0"
        >
          <div>
            <input type="file" onChange={handleFileChange} />
            <div style={{ color: "grey", fontStyle: "italic" }}>
              format file .txt
            </div>
          </div>

          <Form.Item className="gx-text-center gx-mb-0 gx-mt-3">
            <ButtonBasic
              style={{ width: "100%" }}
              loading={loader}
              htmlType="submit"
            >
              {loader ? "Loading..." : "Login"}
            </ButtonBasic>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default LoginAntreanDashboard;
