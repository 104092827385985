import React from "react";
import { Modal, Row, Col } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";

export const modalAlert = (content) => {
  Modal.error({
    centered: true,
    icon: <ExclamationCircleFilled />,
    okType: "danger",
    content: (
      <div>
        <p>{content}</p>
      </div>
    ),
    title: (
      <Row
        type="flex"
        justify="start"
        style={{ alignItems: "center" }}
        gutter={[5, 0]}
      >
        <Col>
          <span>Warnings !</span>
        </Col>
      </Row>
    ),
    onOk() {
      Modal.destroyAll();
    },
    onCancel() {},
  });
};

export default modalAlert;
